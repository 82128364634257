import { graphql, useStaticQuery } from 'gatsby';

import Collapsible from 'react-collapsible';
import { Modal } from 'react-bootstrap';
import ModalComponent from './modal';
import React from 'react';
import closedIcon from '../../images/icons/collapsible-closed.svg';
import openIcon from '../../images/icons/collapsible-open.svg';
import styled from 'styled-components';

const BackgroundWrapper = styled.div`
	background-image: url(${(props) => props.bg});
	box-shadow: 0px 32px 102px rgba(23, 9, 40, 0.36);
	border-radius: 16px;
`;

const PopupChannels = ({ show, setShow, channelId }) => {
	const data = useStaticQuery(CHANNELS);

	const allData = getChannelsData(data, channelId);

	return (
		<>
			<ModalComponent show={show} setShow={setShow} dialogClassName="modal-popup">
				<BackgroundWrapper bg={data.bg.childImageSharp.fluid.src}>
					<Modal.Header>
						<Modal.Title id="custom-modal-styling-title" className="w-100">
							<div className="row w-100 d-flex justify-content-end">
								<span
									className="icon popup-close"
									onClick={() => {
										setShow(false);
									}}
								></span>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="px-sm-5">
							<p>Виж всички твои</p>
							<h2 className="mb-4">Любими ТВ програми</h2>
						</div>

						{allData.map(({ title, channels }, index) => (
							<ChannelSection
								key={title + index}
								title={title}
								channels={channels}
								isInitiallyOpen={index === 0}
							/>
						))}
					</Modal.Body>
				</BackgroundWrapper>
			</ModalComponent>
		</>
	);
};

const ChannelSection = ({ title, channels, isInitiallyOpen }) => {
	return (
		<>
			<Collapsible
				open={isInitiallyOpen}
				trigger={<CollapsibleHeader isOpen={false} title={title} />}
				triggerWhenOpen={<CollapsibleHeader isOpen={true} title={title} />}
			>
				<ChannelRow channels={channels} isPopup={true} />
			</Collapsible>
		</>
	);
};

export const ChannelRow = ({ channels, isPopup }) => {
	return (
		<>
			{channels.length > 0 && (
				<div className="row">
					{channels.map(({ node }, index) => (
						<div
							key={index}
							className={
								'col-4 p-2 mb-5 d-flex justify-content-center ' +
								(isPopup ? 'col-sm-2' : 'col-sm-3')
							}
						>
							<img
								width={90}
								height={90}
								src={node.signedUrl[0]}
								alt="Канал"
							/>
						</div>
					))}
				</div>
			)}
		</>
	);
};

const CollapsibleHeader = ({ title, isOpen }) => {
	return (
		<>
			<div className="row w-100">
				<div className="d-inline-block w-100">
					<button className="clear" type="button">
						<img
							src={isOpen ? openIcon : closedIcon}
							className="d-inline-block"
							alt="Тригър"
						/>
						<h3 className={'d-inline-block pb-0'}>{title}</h3>
					</button>
					<hr className={'ml-5 collapsible channel-popup'} />
				</div>
			</div>
		</>
	);
};

export default PopupChannels;

const getChannelsData = (data, id) => {
	switch (id) {
		case '75':
			return [
				{
					title: 'Политематични',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels75.edges.filter(({ node }) => {
						return node.name.includes('75/9. За възрастни (18+)');
					})
				}
			];
		case '110':
			return [
				{
					title: 'Политематични',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels110.edges.filter(({ node }) => {
						return node.name.includes('110/9. За възрастни (18+)');
					})
				}
			];
		case '170':
			return [
				{
					title: 'Политематични',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels170.edges.filter(({ node }) => {
						return node.name.includes('170/9. За възрастни (18+)');
					})
				}
			];
		case '200':
			return [
				{
					title: 'Политематични',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels200.edges.filter(({ node }) => {
						return node.name.includes('200/9. За възрастни (18+)');
					})
				}
			];

		case '200sport':
			return [
				{
					title: 'Политематични',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels200sport.edges.filter(({ node }) => {
						return node.name.includes('200sport/. За възрастни (18+)');
					})
				}
			];
		case '200az':
			return [
				{
					title: 'Политематични',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('200az/9. За възрастни (18+)');
					})
				}
			];
		case '175movie':
			return [
				{
					title: 'Политематични',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels175movie.edges.filter(({ node }) => {
						return node.name.includes('175movie/9. За възрастни (18+)');
					})
				}
			];
		case '175sport':
			return [
				{
					title: 'Политематични',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels175sport.edges.filter(({ node }) => {
						return node.name.includes('175sport/9. За възрастни (18+)');
					})
				}
			];

		default:
			return [
				{
					title: 'Политематични',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('1. Политематични');
					})
				},
				{
					title: 'Новини',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('2. Новини');
					})
				},
				{
					title: 'Филми',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('3. Филми');
					})
				},
				{
					title: 'Детски',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('4. Детски');
					})
				},
				{
					title: 'Спорт',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('5. Спорт');
					})
				},
				{
					title: 'Хоби',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('6. Хоби Лайфстайл');
					})
				},
				{
					title: 'Музика',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('7. Музика');
					})
				},
				{
					title: 'Документални',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('8. Документални');
					})
				},
				{
					title: 'За възрастни (18+)',
					channels: data.channels200az.edges.filter(({ node }) => {
						return node.name.includes('9. За възрастни (18+)');
					})
				}
			];
	}
};

const CHANNELS = graphql`
	query Channles {
		bg: file(relativePath: { eq: "popup-channels-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
		channels75: allGoogleStorage(filter: { name: { regex: "/75/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels110: allGoogleStorage(filter: { name: { regex: "/110/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels170: allGoogleStorage(filter: { name: { regex: "/170/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels200: allGoogleStorage(filter: { name: { regex: "/200/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels200sport: allGoogleStorage(filter: { name: { regex: "/200sport/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels175movie: allGoogleStorage(filter: { name: { regex: "/175movie/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels175sport: allGoogleStorage(filter: { name: { regex: "/175sport/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
		channels200az: allGoogleStorage(filter: { name: { regex: "/200az/" } }) {
			edges {
				node {
					name
					signedUrl
				}
			}
		}
	}
`;

